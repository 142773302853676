<template>
  <div ref="fullscreenContainer" class="full-screen-container">
    <!-- <SideControl
      v-if="no_logout && clientStatus === CONNECTED_STATUS"
      @logout="LogOut"
      @fullscreen="fullscreen"
      @disconnectKeyboard="disconnectKeyboardEvents"
    /> -->
    <TopBar 
      @fullscreen="fullscreen" 
      @restart="restartGuacamole"
    />
    <div
      v-if="!networkConnected"
      style="
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <img alt="Vue logo" class="logo" src="/images/rafale.png" style="width: 40%;"/>
      <p style="text-align: center; font-weight: bold; color: white; background-color: black; padding: 0px 10px;" >
        Has perdido acceso, intenta reconectarte
      </p>
      <div v-if="connectionMessage" class="" style="color: white; background-color: black; padding: 0px 10px;">
        <p>{{ connectionMessage }}</p>
      </div>
    </div>
    <div
      ref="guacamoleContainer"
      class="full-screen-container nocursor"
      v-show="clientStatus === CONNECTED_STATUS"
      @click="setupKeyboardEvents"
    ></div>

    <!-- Display Average Latency or Connection Messages -->
    <!--div v-if="displayMessage !== null" class="status-message">
      <pre>{{ displayMessage }}</pre>
    </div-->
  </div>
</template>

<script>
import Guacamole from 'guacamole-common-js'
import CryptoJS from 'crypto-js'
import SideControl from './side-controls.vue'
import TopBar from './top-bar.vue';

export default {
  name: 'GuacamoleStage',
  components: {
    TopBar,
    SideControl,
  },
  emits: ['display-login'],
  data() {
    return {
      client: null,
      hostname: '',
      username: '',
      password: '',
      port: '',
      scale: 1,
      token: '',
      wrapper: null,
      clientStatus: 0, // Initial status
      optimal_height: 0,
      optimal_width: 0,
      keyboard: null,
      no_logout: true,
      networkConnected: true,
      hasStoredCredentials: false,
      pingResult: null, // Holds the average latency
      pingIntervalId: null, // Stores the interval ID for periodic ping
      pingsLost: false, // Tracks if pings are being lost
      reconnecting: false, // Tracks if reconnection is in progress
      displayMessage: null, // Message to display in place of ping result
      connectionMessage: null, // Holds the current connection status message
      nombre_maquina: '',
    }
  },
  props: {
    encryptedToken: String,
    userData: Object
  },
  computed: {
    CONNECTED_STATUS() {
      return Guacamole.Client.State.CONNECTED
    },
    DISCONNECTED_STATUS() {
      return Guacamole.Client.State.DISCONNECTED
    }
  },
  unmounted() {
    localStorage.removeItem('no_logout')
  },
  mounted() {
    const no_logout = localStorage.getItem('no_logout')
    this.no_logout = !no_logout

    let storedData = localStorage.getItem('credentials')
    let data = storedData ? JSON.parse(storedData) : null
    if (data) {
      this.hasStoredCredentials = true
      this.hostname = data.hostname
      this.username = data.username
      this.password = data.password
      this.port = data.port
      this.nombre_maquina = data.nombre_maquina
    } else {
      if (this.userData) {
        data = this.userData
      } else if (this.encryptedToken) {
        data = this.decryptToken(this.encryptedToken)
        console.log(data, 'asdda2')
      }

      if (data) {
        this.hostname = data.hostname
        this.username = data.username
        this.password = data.password
        this.port = data.port
        this.port = data.nombre_maquina

        localStorage.setItem('credentials', JSON.stringify(data))
      }
    }
    this.initializeGuacamoleClient()
    window.addEventListener('resize', this.resizeGuacamoleDisplay)
    
    window.addEventListener('offline', this.handleOffline);
    // window.addEventListener('online', this.handleOnline);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeGuacamoleDisplay)
    window.removeEventListener('offline', this.handleOffline);
    // window.removeEventListener('online', this.handleOnline);
  },
  watch: {
    clientStatus(newStatus) {
      if (newStatus === this.CONNECTED_STATUS) {
        if (this.$refs.guacamoleContainer && this.client) {
          // Clear the container before appending to avoid duplicates
          this.$refs.guacamoleContainer.innerHTML = ''
          this.$refs.guacamoleContainer.appendChild(this.client.getDisplay().getElement())
        }
        // Reset the display message to show ping results
        this.connectionMessage = null
      }
    },
    pingResult(newPingResult) {
      if (this.clientStatus === this.CONNECTED_STATUS && !this.connectionMessage) {
        this.displayMessage = `Average Latency: ${newPingResult} ms`
      }
    }
  },
  methods: {
    handleOffline() {
      this.networkConnected = false
            this.connectionMessage = 'Connection Lost'

            if (!this.reconnecting) {
              this.startReconnection()
            }
      console.log('offline')
    },
    async initializeGuacamoleClient() {
      const container = this.$refs.guacamoleContainer
      console.log(container)
      if (container == 0) {
      }

      const width = container.offsetWidth
      const height = container.offsetHeight

      this.optimal_height = height
      this.optimal_width = width
      this.token =
        this.encryptToken({
          connection: {
            type: 'rdp',
            settings: {
              hostname: this.hostname,
              username: this.username,
              password: this.password,
              'enable-drive': true,
              port: '3389',
              'create-drive-path': false,
              'enable-audio-input': true,
              'enable-audio': true,
              security: 'any',
              'ignore-cert': true,
              'enable-wallpaper': true,
              'server-layout': 'es-latam-qwerty',
              'resize-method': 'display-update',
              cursor: false
            }
          }
        }) +
        `&width=${window.innerWidth}` +
        `&height=${window.innerHeight}` +
        `&GUAC_AUDIO=audio/L8&GUAC_AUDIO=audio/L16`

      this.clientStatus = Guacamole.Client.State.IDLE
      const tunnel = new Guacamole.WebSocketTunnel('wss://rafale.qinaya.co/')
      this.client = new Guacamole.Client(tunnel)

      this.setupKeyboardEvents()
      this.setupMouseEvents()
      this.setupTouchEvents()

      setInterval(() => {
        console.log(tunnel.isConnected())
        if (tunnel.isConnected() == false ) {
          this.networkConnected = false
          if (!this.reconnecting) {
            this.startReconnection()
          }
        }
      }, 5000)

      tunnel.onerror = (status) => {
        // console.log(status,'puta madre', status.code == 519)
        if (status.code == 519 || status.code == 514) {
          this.networkConnected = false
          this.networkConnected = false
            this.connectionMessage = 'Connection Lost'

            if (!this.reconnecting) {
              this.startReconnection()
            }
        }
      }

      this.client.onstatechange = (state) => {
        this.clientStatus = state
        switch (state) {
          case Guacamole.Client.State.CONNECTED:
            console.log('Client connected')
            this.requestAudioStream()
            this.networkConnected = true
            this.reconnecting = false // Reset reconnection flag
            clearInterval(this.countdownInterval) // Clear countdown interval if it exists
            this.countdown = 5 // Reset countdown
            break
          case Guacamole.Client.State.DISCONNECTED:
            console.log('Client disconnected')
            this.networkConnected = false
            this.connectionMessage = 'Connection Lost'

            if (!this.reconnecting) {
              this.startReconnection()
            }
            break
          default:
            break
        }
      }

      this.client.connect('token=' + this.token)
    },
    restartGuacamole() {
      if (this.client) {
        this.client.disconnect() // Close the existing client connection
      }
      location.reload()
    },
    startReconnection() {
      this.reconnecting = true // Set reconnection flag
      this.countdown = 5 // Start countdown at 3 seconds

      // Display the countdown message
      this.connectionMessage = `Intentando reconexion en ${this.countdown}...`

      // Start the countdown interval
      this.countdownInterval = setInterval(() => {
        this.countdown -= 1

        // Update the countdown message
        this.connectionMessage = `Intentando reconexion en ${this.countdown}...`

        // When countdown reaches zero, attempt reconnection
        if (this.countdown === 0) {
          clearInterval(this.countdownInterval)
          this.countdown = 5 // Reset the countdown for future use
          this.reconnectClient()
        }
      }, 1000) // Decrease countdown every second
    },

    // New method to handle reconnection logic
    reconnectClient() {
      console.log('Attempting to reconnect...')
      if (this.client) {
        this.client.disconnect() // Close the existing client connection
      }
      location.reload()
    },
    requestAudioStream() {
      if (!this.client) return
      let stream = this.client.createAudioStream(`audio/L16;rate=44100,channels=2`)
      let recorder = Guacamole.AudioRecorder.getInstance(stream, 'audio/L16;rate=44100,channels=2')
      if (!recorder) {
        stream.sendEnd()
      } else {
        recorder.onclose = this.requestAudioStream.bind(this.client)
      }
    },
    resizeGuacamoleDisplay() {
      const container = this.$refs.guacamoleContainer
      if (!container) return
      
      const newWidth = container.offsetWidth
      const newHeight = container.offsetHeight

      if (this.client) {
        const display = this.client.getDisplay()
        this.scale = newWidth / display.getWidth()

        if (this.client) {
          this.scale = newWidth / display.getWidth()
          this.client.sendSize(newWidth, newHeight)
        }
      }
    },
    fullscreen() {

      const elem = this.$refs.fullscreenContainer

      if (!document.fullscreenElement) {

        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
      } else {

        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    setupKeyboardEvents() {
      if (this.keyboard != null) return
      if (!this.client) return
      console.log('Setting up keyboard events')

      const guac = this.client
      this.keyboard = new Guacamole.Keyboard(document)

      this.keyboard.onkeydown = function (keysym) {
        guac.sendKeyEvent(1, keysym)
      }

      this.keyboard.onkeyup = function (keysym) {
        guac.sendKeyEvent(0, keysym)
      }
    },
    disconnectKeyboardEvents() {
      console.log('Disconnecting keyboard events')
      if (this.keyboard) {
        this.keyboard.onkeydown = null
        this.keyboard.onkeyup = null
        this.keyboard = null
      }
    },
    setupMouseEvents() {
      if (!this.client) return
      const guac = this.client
      const displayElement = guac.getDisplay().getElement()
      const mouse = new Guacamole.Mouse(displayElement)

      mouse.onmousedown =
        mouse.onmouseup =
        mouse.onmousemove =
          (mouseState) => {
            guac.sendMouseState(mouseState)
          }
    },
    setupTouchEvents() {
      if (!this.client) return
      const guac = this.client
      const mouse = new Guacamole.Mouse.Touchpad(guac.getDisplay().getElement())

      mouse.onmousedown =
        mouse.onmouseup =
        mouse.onmousemove =
          function (mouseState) {
            guac.sendMouseState(mouseState)
          }
    },
    encryptToken(value) {
      const SECRET_KEY = 'YJdej0QhgdJ7Phagg9k0CFob9p3mbYpY'
      const iv = CryptoJS.lib.WordArray.random(128 / 8)

      const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)

      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

      const data = {
        iv: CryptoJS.enc.Base64.stringify(iv),
        value: encrypted.toString()
      }

      const json = JSON.stringify(data)
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(json))
    },
    decryptToken(encryptedToken) {
      const SECRET_KEY = 'YJdej0QhgdJ7Phagg9k0CFob9p3mbYpY'

      const decodedData = CryptoJS.enc.Base64.parse(encryptedToken).toString(CryptoJS.enc.Utf8)
      const data = JSON.parse(decodedData)

      const iv = CryptoJS.enc.Base64.parse(data.iv)
      const encryptedValue = data.value

      const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)

      const decrypted = CryptoJS.AES.decrypt(encryptedValue, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

      const decryptedValue = CryptoJS.enc.Utf8.stringify(decrypted)
      return JSON.parse(decryptedValue)
    },
    LogOut() {
      localStorage.clear()
      this.clientStatus = 0
      this.disconnectKeyboardEvents()
      this.$emit('display-login', null)
    }
    // onePing function to measure latency for a single request
  }
}
</script>

<style scoped>
.full-screen-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.nocursor {
  cursor: none;
}

.nocursor:hover {
  cursor: none;
}

/* Styles for the status message display */
.status-message {
  position: absolute;
  top: 10px; /* Position from the top */
  left: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Additional styles if needed */
</style>
