<template>
  <div class="iframe-container">
    <iframe 
      ref="mirageFrame"
      :src="mirage_url" 
      frameborder="0" 
      class="mirage-frame"
      tabindex="0"
      @load="handleIframeLoad"
    ></iframe>
    <div v-if="showStatus" class="keyboard-status">
      Keyboard Connected
    </div>
  </div>
</template>

<script>
export default {
  name: 'MirageStage',
  props: {
    mirage_url: String 
  },
  data() {
    return {
      showStatus: false,
    }
  },
  methods: {
    handleIframeLoad() {
      // Wait for 5 seconds after iframe loads before focusing
      setTimeout(() => {
        if (this.$refs.mirageFrame) {
          this.$refs.mirageFrame.focus();
          this.showStatus = true;
          // Hide the status message after 3 seconds
          setTimeout(() => {
            this.showStatus = false;
          }, 3000);
        }
      }, 5000);
    }
  }
}
</script>

<style scoped>
.iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.mirage-frame {
  border: none;
  width: 100%; 
  height: 100vh;
}

.keyboard-status {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(34, 197, 94, 0.1);
  color: rgb(22, 101, 52);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: opacity 0.3s;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}
</style>